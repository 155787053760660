@import 'https://api.tiles.mapbox.com/mapbox-gl-js/v2.3.0/mapbox-gl.css';

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  min-height: 100%;
  min-height: calc(100% + constant(safe-area-inset-top));
  min-height: calc(100% + env(safe-area-inset-top));
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-size-adjust: none;
  background-color: #f9f5ed;
}

p,
img {
  vertical-align: middle;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

input,
button,
select {
  font-family: inherit;
  font-size: inherit;
}

a {
  color: #007aff;
}

a:active {
  filter: brightness(0.8);
}

#map {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  transition: filter 0.3s ease-in-out;
  background-color: #f9f5ed;
}
#map.fade-out {
  pointer-events: none;
  filter: brightness(0.7);
}
#map.fade-out + header {
  pointer-events: none;
}

.mapboxgl-canvas {
  outline: 0;
}
.mapboxgl-ctrl-attrib {
  margin-top: 5px !important;
}
.mapboxgl-ctrl-group {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 123123px;
}
@supports (backdrop-filter: blur(10px)) {
  .mapboxgl-ctrl-group {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px) saturate(3);
  }
}
.mapboxgl-ctrl-group button {
  opacity: 0.8;
  width: 36px;
  height: 36px;
}
.mapboxgl-ctrl-attrib.mapboxgl-compact {
  min-height: 24px;
  margin-top: 5px;
}
.mapboxgl-ctrl-attrib.mapboxgl-compact:not(:hover) {
  background-color: transparent;
}
@supports (top: max(1px)) {
  .mapboxgl-ctrl-top-right {
    top: max(0px, env(safe-area-inset-top));
    right: max(0px, env(safe-area-inset-right));
  }
}

#logo {
  position: fixed;
  top: 12px;
  top: calc(12px + env(safe-area-inset-top));
  left: 12px;
  z-index: 1;
  cursor: pointer;
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.3));
  transition: opacity 0.3s ease-in-out;
}
@supports (top: max(1px)) {
  #logo {
    left: max(12px, env(safe-area-inset-left));
  }
}
#logo.fadeout {
  opacity: 0.5;
}
#logo.fadeout:hover {
  opacity: 1;
}
#logo:active {
  transform: scale(0.95);
  filter: brightness(0.9);
}
#logo h1 {
  margin: 0;
  padding: 0;
}

#about {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  perspective: 500px;
}
#about[hidden] {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  display: block;
}
#about section {
  position: absolute;
  bottom: 0;
  line-height: 1.4;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 12px;
  padding-bottom: calc(12px + env(safe-area-inset-bottom));
  border-radius: 15px 15px 0 0;
  box-shadow: 0 -1px 200px rgba(0, 0, 0, 0.3);
  font-size: 0.9em;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}
@supports (backdrop-filter: blur(10px)) {
  #about section {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px) saturate(3);
  }
}
#about[hidden] section {
  opacity: 0;
  transform: translate3d(0, 100%, -50px) rotateX(35deg);
}
#about h2 {
  margin: 0;
  padding: 0;
  font-size: 1.2em;
  line-height: 1;
}
#about hr {
  border: 0;
  border-top: 1px solid #ccc;
  height: 0;
  padding: 0;
}
#about a {
  text-decoration: none;
}

.popover {
  position: fixed;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px 15px 0 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: 2;
}
@supports (backdrop-filter: blur(10px)) {
  .popover {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px) saturate(3);
  }
}

#search-popover {
  top: -60px;
  height: 100%;
  bottom: 0;
  transform: translateY(55%);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}
@supports (top: min(1px)) {
  #search-popover {
    top: min(-60px, calc(-60px - env(safe-area-inset-bottom) + 14px));
  }
}
#search-popover.shrink {
  transform: translateY(100%);
}
#search-popover.shrink:not(.expand) .popover-list {
  opacity: 0.5;
}
#search-popover.expand {
  transform: translateY(80px);
  transform: translateY(
    calc(80px + env(safe-area-inset-top) + env(safe-area-inset-bottom))
  );
}
#search-popover.loading {
  opacity: 0.75;
  pointer-events: none;
}
#search-popover .popover-inner {
  border-radius: 15px 15px 0 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}
@supports (backdrop-filter: blur(10px)) {
  #search-popover {
    background-color: transparent;
    backdrop-filter: none;
  }
  #search-popover .popover-inner {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px) saturate(3);
  }
}

#stop-popover,
#between-popover,
#arrivals-popover,
#service-popover {
  top: 101%;
  transition: all 0.3s 0.1s ease-in-out;
  max-height: 50vh;
  display: flex;
  flex-direction: column;
}
#stop-popover.expand,
#between-popover.expand,
#arrivals-popover.expand,
#service-popover.expand {
  transform: translateY(-101%);
}
#stop-popover.expand {
  z-index: 3;
}
#arrivals-popover.expand {
  z-index: 4;
}
#stop-popover.expand ~ #service-popover.expand {
  transform: translateY(-80px);
  pointer-events: none;
}
#stop-popover header,
#between-popover header,
#service-popover header {
  flex-shrink: 0;
  padding: 12px 40px 0 12px;
}
#stop-popover h1,
#between-popover h1,
#service-popover h1 {
  font-size: 1em;
  margin: 0 0 0.5em;
  padding: 0;
  display: flex;
  align-items: flex-start;
}
#stop-popover h1 .stop-tag,
#between-popover h1 .stop-tag,
#service-popover h1 .service-tag {
  margin: 0 0.5em 0 0;
}
#service-popover h1 .service-tag {
  margin-top: -0.2em;
}
#stop-popover header h1 {
  cursor: pointer;
}
#stop-popover h2,
#between-popover h2,
#service-popover h2 {
  font-size: 0.8em;
  font-weight: normal;
  margin: 0.5em 0 0;
  padding: 0;
  color: #999;
  text-transform: uppercase;
}
#stop-popover h2,
#service-popover h2 {
  margin-top: 0;
  margin-bottom: 0.5em;
}
#stop-popover h2 a,
#service-popover h2 a {
  color: inherit;
  text-decoration: none;
}
#stop-popover h2 a:hover,
#service-popover h2 a:hover {
  text-decoration: underline;
}
#stop-popover h2 img.new-window,
#service-popover h2 img.new-window {
  filter: invert(1) opacity(0.4);
}
#stop-popover h3,
#between-popover h3,
#service-popover h3 {
  font-size: 0.7em;
  margin: 1em 0 0.5em;
  padding: 0;
  color: #666;
  text-transform: uppercase;
}
#stop-popover p,
#between-popover p,
#service-popover p {
  margin: 0;
  padding: 0;
}
#stop-popover .popover-scroll,
#between-popover .popover-scroll,
#service-popover .popover-scroll {
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 12px;
}
#stop-popover .service-tag {
  margin-bottom: 4px;
}
#stop-popover .popover-scroll {
  padding-bottom: 0;
  padding-top: 0;
}
#between-popover .popover-scroll,
#service-popover .popover-scroll {
  padding-top: 0;
}
#stop-popover .popover-footer {
  padding: 12px;
}
@supports (top: max(1px)) {
  #between-popover .popover-scroll {
    padding-left: max(12px, env(safe-area-inset-left));
    padding-right: max(12px, env(safe-area-inset-right));
    padding-bottom: max(12px, env(safe-area-inset-bottom));
  }
  #service-popover .popover-scroll {
    padding-left: max(12px, env(safe-area-inset-left));
    padding-right: max(12px, env(safe-area-inset-right));
    padding-bottom: max(12px, env(safe-area-inset-bottom));
  }
  #stop-popover .popover-footer {
    padding-left: max(12px, env(safe-area-inset-left));
    padding-right: max(12px, env(safe-area-inset-right));
    padding-bottom: max(12px, env(safe-area-inset-bottom));
  }
}

#stop-popover .services-list span {
  transition: opacity 0.3s;
}
#stop-popover .services-list.loading span {
  opacity: 0.75;
  pointer-events: none;
}

#between-popover .between-block {
  margin: 1em 0;
}

#between-popover .between-nada {
  font-size: 14px;
  color: #333;
}

#between-popover .between-item {
  border-radius: 12px;
  padding: 12px;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  margin-bottom: 6px;
  cursor: pointer;
}
#between-popover .between-item:hover {
  border-color: #a4d0ff;
  background-color: #fff;
}
#between-popover .between-item.selected {
  border-color: #007aff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

#between-popover .between-inner {
  pointer-events: none;
  position: relative;
  height: 40px;
  opacity: 0.6;
  transition: opacity 0.3s ease-in-out;
}
#between-popover .between-item:hover .between-inner,
#between-popover .between-item.selected .between-inner {
  opacity: 1;
}

#between-popover .between-services {
  font-size: 14px;
}
#between-popover .between-services span {
  position: absolute;
  width: 70%;
  text-align: center;
  display: block;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  background-position: bottom;
}
#between-popover .between-services .start {
  padding-right: 2em;
  padding-bottom: 2px;
  left: 5px;
  background-image: linear-gradient(to left, transparent 0%, #f01b48 30%);
}
#between-popover .between-services.full .start {
  width: auto;
  right: 5px;
}
#between-popover .between-services.full .start {
  background-image: linear-gradient(to left, #972ffe, #f01b48);
  padding-right: 0;
}
#between-popover .between-services .end {
  padding-left: 2em;
  padding-bottom: 6px;
  right: 5px;
  background-image: linear-gradient(to right, transparent 0%, #972ffe 30%);
}
#between-popover .nearby-start .between-services .start:before,
#between-popover .nearby-end .between-services .end:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 32px;
  bottom: 0;
  border-bottom: 2px dotted #fff;
  background: url(images/walk.svg) no-repeat center;
  background-size: 14px;
}
#between-popover .nearby-start .between-services .start:before {
  left: 0;
}
#between-popover .nearby-end .between-services .end:before {
  right: 0;
}

#between-popover .between-stops {
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 10px;
  display: flex;
  padding-top: 1.2em;
}
#between-popover .between-stops:before,
#between-popover .between-stops:after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  border: 3px solid;
  background-color: #fff;
}
#between-popover .between-stops:before {
  border-color: #f01b48;
  margin-right: 24px;
}
#between-popover .between-stops:after {
  border-color: #972ffe;
  margin-top: 4px;
  margin-left: 24px;
}
#between-popover .between-stops.nada:after {
  margin-top: 0;
}
#between-popover .between-stops .start,
#between-popover .between-stops .end {
  display: none;
}

#between-popover .nearby-start .between-stops .start,
#between-popover .nearby-end .between-stops .end {
  display: block;
}
#between-popover .nearby-start .between-stops .start:before,
#between-popover .nearby-end .between-stops .end:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border: 3px solid;
  border-color: #f01b48 #972ffe #972ffe #f01b48;
  background-color: #fff;
  border-radius: 100px;
}
#between-popover .nearby-start .between-stops .start:before {
  margin-top: 1px;
  border-color: #f01b48;
}
#between-popover .nearby-end .between-stops .end:before {
  margin-left: auto;
  margin-top: 5px;
  border-color: #972ffe;
}

#between-popover .between-stops .betweens {
  text-align: center;
  display: block;
  padding-top: 3px;
  flex-grow: 1;
}
#between-popover .between-stops.nada .betweens {
  visibility: hidden;
}
#between-popover .between-stops .betweens:before {
  content: '';
  display: block;
  margin: auto;
  width: 10px;
  height: 10px;
  border: 3px solid;
  border-color: #f01b48 #972ffe #972ffe #f01b48;
  background-color: #fff;
  border-radius: 100px;
}
#between-popover .between-stops .betweens-2:before {
  width: 15px;
}
#between-popover .between-stops .betweens-3:before {
  width: 20px;
}
#between-popover .between-stops .betweens-4:before {
  width: 25px;
}
#between-popover .between-stops .betweens-5:before {
  width: 30px;
}
#between-popover .between-stops .betweens-6:before {
  width: 35px;
}

.callout {
  padding: 12px;
  border-radius: 12px;
  font-size: 0.8em;
  line-height: 1.3;
}
.warning {
  background-color: papayawhip;
  color: saddlebrown;
  font-weight: bold;
}
.info {
  background-color: lightcyan;
  color: midnightblue;
}

#arrivals-popover {
  max-height: none;
  height: calc(100% - 20px);
  height: calc(100% - env(safe-area-inset-top) - 20px);
  overflow: hidden;
  background-color: #fff;
  transition: transform 0.15s ease-in-out;
}
#arrivals-popover .popover-scroll {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
#arrivals-popover iframe {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 20px);
  border: 0;
  opacity: 0;
  transition: opacity 0.3 1s ease-in-out;
}
#arrivals-popover.expand iframe {
  opacity: 1;
}

#arrivals-popover .popover-close {
  z-index: 1;
  backdrop-filter: blur(10px) saturate(3);
}
#arrivals-popover .popover-popout {
  right: 38px;
  width: auto;
  padding: 0 12px;
  text-transform: uppercase;
  font-size: 14px;
}
#arrivals-popover .popover-popout img {
  vertical-align: text-bottom;
  filter: brightness(0.5);
}
#arrivals-popover .popover-popout:hover img {
  filter: brightness(0.2);
}
.standalone #arrivals-popover .popover-popout {
  display: none;
}

.popover-search {
  padding: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 60px;
  display: flex;
}

.popover-search input[type='search'] {
  display: block;
  width: 100%;
  appearance: none;
  height: 30px;
  line-height: 30px;
  border-radius: 10px;
  border: 0;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0 7px;
}
.popover-search input[type='search']:focus {
  background-color: rgba(255, 255, 255, 0.5);
}
.popover-search input[type='search']:active {
  background-color: rgba(0, 0, 0, 0.2);
}

.popover-search button {
  display: none;
  height: 30px;
  padding: 0 0 0 14px;
  position: relative;
  color: #007aff;
  cursor: pointer;
  appearance: none;
  border: 0;
  margin: 0;
  background: transparent none;
  -webkit-tap-highlight-color: transparent;
}
.popover.expand .popover-search button {
  display: inline-block;
}
.popover-search button:active {
  opacity: 0.4;
}

.popover-list {
  flex-grow: 1;
  margin: 0;
  padding: 0 0 60px;
  list-style: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.popover-list.loading {
  opacity: 0.5;
  pointer-events: none;
}
.popover-list.loading .ads {
  pointer-events: auto;
}
.popover-list.searching .ads {
  display: none;
}
.popover-list li.nada {
  padding: 14px;
  pointer-events: none;
}
.popover-list li:not(.ads) a {
  padding: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  text-decoration: none;
  align-items: center;
  color: #000;
}
@media (hover: hover) {
  .popover-list li:not(.ads) a:hover {
    background-color: rgba(255, 255, 255, 0.6);
  }
}
.popover-list li a [class*='-tag'] {
  margin-right: 1em;
}
.popover-list li a.current {
  background-color: #dbefb7;
  pointer-events: none;
}

.stops-table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
}
.stops-table,
.stops-list {
  margin-bottom: 16px;
}
.stops-list,
.stops-list li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.stops-table td.stop-start {
  position: relative;
  background: radial-gradient(
    circle at bottom center,
    transparent,
    transparent 11px,
    #f01b48 11px,
    #f01b48 13px,
    transparent 13px
  );
}
.stops-table td.stop-end,
.stops-table td.stop-u {
  position: relative;
  background: radial-gradient(
    circle at top center,
    transparent,
    transparent 11px,
    #f01b48 11px,
    #f01b48 13px,
    transparent 13px
  );
}
.stops-table td.stop-u {
  height: 20px;
}
.stops-list li,
.stops-table td.stop,
.stops-table td.empty {
  position: relative;
  background: linear-gradient(#f01b48, #f01b48) no-repeat 4px 0;
  background-size: 2px 100%;
}
.stops-table td.stop {
  width: calc(50% - 7px);
}
.stops-table td.stop:first-child,
.stops-table td.empty:first-child {
  background-position: right 4px top 0;
}
.stops-list > li:first-child,
.stops-table tr:first-of-type > td.stop,
.stops-table tr > td.stop.first,
.stops-table tr:first-of-type > td.empty {
  background-position: 4px bottom;
  background-size: 2px 50%;
}
.stops-table tr:first-of-type > td.stop:first-child,
.stops-table tr > td.stop.first:first-child,
.stops-table tr:first-of-type > td.empty:first-child {
  background-position: right 4px bottom 0;
}
.stops-list > li:last-child,
.stops-table tr:last-of-type > td.stop,
.stops-table tr > td.stop.last,
.stops-table tr:last-of-type > td.empty {
  background-position: 4px top;
  background-size: 2px 50%;
}
.stops-table tr:last-of-type > td:first-child.stop,
.stops-table tr > td:first-child.stop.last,
.stops-table tr:last-of-type > td:first-child.empty {
  background-position: right 4px top 0;
}
.stops-list li:before,
.stops-table td.stop-start:before,
.stops-table td.stop-end:before,
.stops-table td.stop:before {
  pointer-events: none;
  content: '';
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid #f01b48;
  background-color: #fff;
  border-radius: 999px;
}
.stops-table td.stop-start:before {
  bottom: 7px;
  left: calc(50% - 5px);
}
.stops-table td.stop-end:before {
  top: 7px;
  left: calc(50% - 5px);
}
.stops-list li:before,
.stops-table td.stop:before {
  top: calc(50% - 5px);
  left: 0;
}
.stops-table td.stop:first-child:before {
  left: auto;
  right: 0;
  margin: 0 0 0 10px;
}
.stops-list li:nth-of-type(3n + 1):not(:last-of-type):after,
.stops-table tr:nth-of-type(3n + 1) td.stop:not(.last):after,
.stops-table tr:nth-of-type(3n + 1) td.empty:not(.last):after,
.stops-table tr.edge + tr td.stop:not(.last):after,
.stops-table tr.edge + tr td.empty:not(.last):after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 1px;
  width: 8px;
  height: 8px;
  border-bottom: 2px solid #f01b48;
  border-right: 2px solid #f01b48;
  transform: rotate(45deg);
  opacity: 0.5;
}
.stops-table tr:nth-of-type(3n + 1) td.stop:not(.last):first-child:after,
.stops-table tr:nth-of-type(3n + 1) td.empty:not(.last):first-child:after,
.stops-table tr.edge + tr td.stop:not(.last):first-child:after,
.stops-table tr.edge + tr td.empty:not(.last):first-child:after {
  left: auto;
  right: 1px;
}
.stops-table tr td.stop:last-child:after,
.stops-table tr td.empty:last-child:after {
  transform: rotate(-135deg) !important;
}
.stops-list li a,
.stops-table td.stop a,
.stops-table td.stop-start a,
.stops-table td.stop-end a {
  display: block;
  text-decoration: none;
  align-items: center;
  color: #000;
  border-radius: 5px;
  transition: 0.1s background-color ease-in-out;
}
.stops-list li a *,
.stops-table td.stop a *,
.stops-table td.stop-start a *,
.stops-table td.stop-end a * {
  pointer-events: none;
}
.stops-list li:first-child a,
.stops-list li:last-child a,
.stops-table td.stop-start a,
.stops-table td.stop-end:not(.loop) a,
.stops-table td.first a,
.stops-table td.last a {
  font-weight: bold;
}
.stops-list li:not(:first-child):not(:last-child) a,
.stops-table td.stop a {
  font-size: 0.9em;
}
.stops-list li a:hover,
.stops-table td.stop a:hover,
.stops-table td.stop-start a:hover,
.stops-table td.stop-end a:hover {
  background-color: #f01b4822;
}
.stops-list li a.flash,
.stops-table td.stop a.flash,
.stops-table td.stop-start a.flash,
.stops-table td.stop-end a.flash {
  background-color: #f01b4822;
  animation: flash 1s both ease-in;
}
@keyframes flash {
  75% {
    background-color: #f01b4822;
  }
  100% {
    background-color: #f01b4800;
  }
}
.stops-table td.stop-start a {
  padding: 5px 5px 20px 5px;
  text-align: center;
}
.stops-table td.stop-end a {
  padding: 20px 5px 5px 5px;
  text-align: center;
}
.stops-list li a,
.stops-table td.stop a {
  padding: 5px 5px 5px 20px;
}
.stops-table td.stop:first-child a {
  padding: 5px 20px 5px 5px;
  text-align: right;
}
.stops-table col.middle {
  width: 14px;
}
span.legend-opposite,
.stops-table td.opposite {
  background: repeating-linear-gradient(
      90deg,
      DodgerBlue,
      DodgerBlue 2px,
      transparent 2px,
      transparent 4px
    )
    center no-repeat;
  background-size: 10px 2px;
}
span.legend-opposite {
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.placeholder {
  color: #aaa;
  letter-spacing: -1px;
  animation: glowing infinite alternate 1s linear both;
}
@keyframes glowing {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.service-tag {
  display: inline-block;
  padding: 3px 7px;
  border: 2px solid #fff;
  border-radius: 5px;
  background-color: #55dd3333;
  color: #3a6727;
  font-weight: 500;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  flex-shrink: 0;
  font-variant-numeric: tabular-nums;
}
h1 .service-tag {
  font-weight: bold;
}
a.service-tag:hover {
  color: #000;
}
.service-tag.current,
.current .service-tag {
  color: #000;
  pointer-events: none;
  animation: currenting infinite linear 0.5s alternate both;
}
.service-tag.highlight {
  color: #000;
  animation: currenting infinite linear 0.5s alternate both;
}
@keyframes currenting {
  0% {
    border-color: #729e1f;
  }
  100% {
    border: 2px solid #fff;
  }
}
.service-tag span {
  font-weight: normal;
  font-size: 0.75em;
  background-color: #fff;
  color: #666;
  padding: 10px 7px;
  margin: 0 -8px 0 5px;
}

.stop-tag {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 4px;
  background-color: #f01b4822;
  color: #f01b48;
  flex-shrink: 0;
  font-variant-numeric: tabular-nums;
  font-size: 12px;
  font-weight: 500;
}
.mini-stop-tag {
  display: inline-block;
  color: #f01b48;
  font-size: 10px;
  font-variant-numeric: tabular-nums;
  font-weight: 500;
}

#popover-float {
  width: 100%;
  position: absolute;
  top: -14px;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  transform: translateY(0);
  padding: 0 12px;
}
#popover-float > *:first-child {
  transform: translateY(-100%);
}
#popover-float[hidden] {
  display: block;
  opacity: 0;
  pointer-events: none;
}
#popover-float[hidden] > *:first-child {
  transform: translateY(0);
}

.float-pill {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 8px;
  border-radius: 7px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 12px;
  margin-left: 12px;
}
@supports (backdrop-filter: blur(10px)) {
  .float-pill {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px) saturate(3);
  }
}
.float-pill .service-flex {
  display: flex;
  align-items: flex-start;
  margin-right: 36px;
}
.float-pill h1 {
  font-size: 1em;
  margin: 0;
  padding: 0;
}
.float-pill p {
  font-size: 0.8em;
  margin: 0;
  padding: 0;
}
.float-pill .service-tag,
.float-pill .stop-tag {
  margin-right: 8px;
  flex-shrink: 0;
}
.float-pill .service-info {
  flex-basis: 85%;
}
.float-pill .services-list {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 35vh;
}
.float-pill .services-list .service-tag {
  margin-top: 4px;
}
@media (min-width: 641px) {
  .float-pill .services-list {
    max-height: 55vh;
  }
}

.popover-close {
  position: absolute;
  top: 7px;
  right: 7px;
  height: 24px;
  width: 24px;
  display: inline-block;
  font-family: Arial, sans-serif;
  line-height: 24px;
  text-align: center;
  font-size: 18px;
  border-radius: 123123px;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.45);
  text-decoration: none;
}
.popover-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.popover-close:active {
  filter: brightness(0.9);
  transform: scale(0.95);
}
.popover-close > * {
  pointer-events: none;
}

.popover.expand #popover-float {
  opacity: 0;
}

.popover-buttons {
  display: flex;
}
.popover-button {
  cursor: pointer;
  border: 0;
  display: block;
  padding: 11px 9px;
  text-align: center;
  background-color: #007aff22;
  color: #007aff;
  font-size: 14px;
  border-radius: 7px;
  text-decoration: none;
  margin-left: 7px;
  flex: 1;
  font-weight: bold;
}
.popover-button:first-child {
  margin-left: 0;
}
.popover-button:focus,
.popover-button:hover {
  filter: contrast(5);
}
.popover-button:active {
  filter: brightness(0.5);
  transform: scale(0.95);
}
.popover-button img {
  vertical-align: text-bottom;
}
.popover-button * {
  pointer-events: none;
}

#tooltip {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px;
  border-radius: 8px;
  transition: opacity 0.3s ease-in-out;
  margin-top: 1.5em;
  white-space: nowrap;
  font-size: 80%;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  z-index: 2;
}
@supports (backdrop-filter: blur(10px)) {
  #tooltip {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px) saturate(3);
  }
}
#tooltip.show {
  opacity: 1;
}

@media (min-width: 481px) {
  #about {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  #about[hidden] {
    display: flex;
  }
  #about section {
    max-width: 480px;
    position: static;
    border-radius: 0 0 7px 7px;
    padding-bottom: 12px; /* Reset safe-area padding */
  }
  #about[hidden] section {
    transform: translate3d(0, -100%, -50px) rotateX(-35deg);
  }

  .popover {
    max-width: 320px;
    right: 16px;
  }
  @supports (top: max(1px)) {
    .popover {
      right: max(16px, env(safe-area-inset-right));
    }
  }

  /* Reset the safe area paddings */
  #between-popover .popover-scroll,
  #stop-popover .popover-footer {
    padding-left: 12px;
    padding-right: 12px;
  }

  #popover-float {
    padding: 0;
  }

  .float-pill {
    right: 0;
    margin-left: 0;
  }
}

@media (min-width: 481px) and (min-height: 551px) {
  #stop-popover,
  #between-popover {
    max-height: 70vh;
  }
}

@media (min-width: 641px) {
  #stop-popover,
  #between-popover,
  #service-popover {
    max-height: calc(100vh - 170px);
  }
  .mapboxgl-ctrl-attrib {
    position: fixed !important;
    left: 0;
    bottom: 0;
    margin: 0 !important;
    background-color: rgba(255, 255, 255, 0.5) !important;
    padding: 2px 10px !important;
  }
  .mapboxgl-ctrl-attrib > * {
    display: inline !important;
  }
  .mapboxgl-ctrl-attrib-button {
    display: none !important;
  }
}

/* Geolocation */

.mapboxgl-ctrl-custom-geolocate {
  color: #333;
  transition: color 0.3s;
}
.mapboxgl-ctrl-custom-geolocate.loading {
  color: #007aff;
  pointer-events: none;
  animation: glowing infinite alternate 1s linear both;
}
.mapboxgl-ctrl-custom-geolocate.active {
  color: #007aff;
}
.mapboxgl-ctrl-custom-geolocate svg {
  vertical-align: middle;
  fill: currentColor;
}
.user-location {
  perspective: 20px;
  pointer-events: none;
}
.user-location-dot {
  width: 20px;
  height: 20px;
  padding: 4px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 123123px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.user-location-dot:after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  overflow: hidden;
  background-color: #007aff;
  border-radius: 123123px;
  animation: wobble 1.5s ease-out infinite alternate both;
}
@keyframes wobble {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.2);
  }
}
.user-location-compass {
  width: 6px;
  height: 20px;
  background: linear-gradient(rgba(0, 122, 255, 0), #007aff);
  position: absolute;
  left: -3px;
  bottom: 0;
  transform-origin: bottom center;
  transform: rotateX(-60deg);
  transition: transform 0.3s;
}
.user-location-accuracy {
  width: 20px;
  height: 20px;
  overflow: hidden;
  background-color: #007aff;
  border-radius: 123123px;
  position: absolute;
  margin: -10px 0 0 -10px;
  animation: pulse 2s 1s ease-out infinite both;
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.2;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}

/* Scroll shadows */

[data-scroll-shadow='top'] {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}
[data-scroll-shadow='bottom'] {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
  background-position: bottom;
  animation: glowing-gradient 1s linear 2s 10 alternate both;
}
[data-scroll-shadow='both'] {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)),
    linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
  background-position: top, bottom;
}
[data-scroll-shadow] {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 100% 16px;
}
@keyframes glowing-gradient {
  0% {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
  }
  6.25% {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1.75));
  }
  12.5% {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  }
  18.75% {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.225));
  }
  25% {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25));
  }
  100% {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25));
  }
}

/* Live badge */

.live {
  display: inline-block;
  font-size: 0.9em;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 2px;
  /* animation: glowing infinite alternate 1s linear both; */
  color: #fff;
  background-color: #00454d;
}

/* Fancy pants features */

[class*='alt-show'] {
  display: none;
}
.alt-mode .alt-show-inline {
  display: none;
}
.alt-mode .alt-show-block {
  display: block;
}
.alt-mode .alt-show-flex {
  display: flex;
}
.alt-mode .alt-hide {
  display: none;
}

/* Standalone-mode styles */

.standalone:before {
  content: '';
  display: block;
  height: env(safe-area-inset-top);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  pointer-events: none;
}

.standalone {
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

/* Donation box */

.donation-box {
  display: block;
  padding: 12px 7px;
  color: #007aff;
  background-color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  border-radius: 7px;
  text-align: center;
  box-shadow: 0 2px 5px #ddd;
  transition: background-color 0.3s ease-in-out;
}
.donation-box:hover {
  color: #000;
  background-color: #fff;
}
